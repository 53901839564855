export const environment = {
  production: true,
  appVersion: '2.49.10-6e66f07d',
  checkVersion: true,
  builtAppHash: '6e66f07d9dd1581ef3b2dc11e5a857389018c7a6',
  centrifugoWebsocketUrl: 'wss://centri.zumti.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://web.verirobot.com/',
  apiUrl: 'https://api.verirobot.com/',
  natsUrl: 'wss://centri.zumti.com',
  googleCientId: '572001134774-bveghvbh9jda001oge723ujj45jbrihq.apps.googleusercontent.com',
  livekitUrl:   'wss://live.verirobot.com',
  customerName: 'verirobotcom',
  s3AccountUrl: 'https://verirobotcom.s3.eu-central-1.amazonaws.com/v2/',
};